<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <div class="page-header">
        <h4 id="top">Activity: <small>{{ activity.title }}</small></h4>
      </div>
    </div>

    <!-- Error Alert -->
    <danger-alert :errors="errors"/>

    <div class="form-group text-left">
      <label for="activity_title">Activity Title</label>
      <input type="text" class="form-control" id="activity_title" v-model="activity.title" placeholder="Title">
    </div>

    <div v-if="!activity.is_archived" class="form-group text-left d-block">
      <b-tooltip target="report_order_tooltip" placement="left" triggers="hover">
        Column number of the field in the Activity Report Summary.
      </b-tooltip>
      <label for="market_area_id">Report Column Number</label>
      <font-awesome-icon id="report_order_tooltip" icon="question-circle" size="md" class="ml-1" />
      <br/>
      <div class="d-inline-block">
        <select class="form-control" v-model="activity.report_order" id="activity_report_order">
          <option v-for="i in activityCount + 2" :value="i" v-bind:key="i.toString()" >{{ i.toString() }}</option>
        </select>
      </div>
    </div>

    <div class="form-group text-left">
      <label for="activity-impediment">Enable Audiences?</label>
      <b-checkbox id="activity-impediment" v-model="activity.coach_impediment"/>
    </div>

    <div class="form-group text-left">
      <label for="topic-impediment">Enable Trainees?</label>
      <b-checkbox id="topic-impediment" v-model="activity.topic_impediment"/>
    </div>

    <div class="form-group text-left">
      <label for="include-in-reports">Include this activity in reports?</label>
      <b-checkbox id="activity-include-in-reports" v-model="activity.include_in_reports"/>
    </div>

    <div class="footer float-right">
      <div class="btn-group">
        <router-link :to="{ name: 'ActivityIndex' }" tag="button" class="btn btn-secondary" type="button">Cancel</router-link>
        <button v-on:click="createActivity" type="button" class="btn btn-success">Create Activity</button>
      </div>
    </div>

    <br>

  </div>
</template>

<script>
import { ActivityService } from '@/common/services/activity.service';
import DangerAlert from '@/common/components/danger-alert.vue';

export default {
  name: 'LanguageNew',
  components: {
    'danger-alert': DangerAlert,
  },
  data() {
    return {
      activity: {
        title: '',
      },
      errors: [],
      activityCount: 0,
    };
  },
  created() {
    this.activityCount = this.$route.params.activityCount || 20;
  },
  methods: {
    createActivity() {
      ActivityService.create(this.activity)
        .then(({ data }) => {
          this.$router.push({ name: 'ActivityShow', params: { id: data.id } }, () => {
            this.$notify({
              title: 'Created Activity',
              text: `Successfully created ${data.title}!`,
              type: 'success',
              width: 350,
            });
          });
        })
        .catch((err) => { this.errors = err.response.data.error; });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/activities";
</style>
